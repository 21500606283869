@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";

.filesFoldersContainer {
  display: flex;
  justify-content: space-between;

  &Terrace {
    transition: opacity .5s ease, transform .25s ease;
    transform: translateX(-20%);
    opacity: 0;
  }

  &Selected {
    transform: none;
    opacity: 1;
  }

  > * {
    transition: flex-basis .25s ease-in-out;
  }

  @media(max-width: $break-point-md) {
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .filePreview {
    flex-basis: 0%;
    transition: height .5s ease-in-out;


    &Selected {
      height: 400px;
      flex-basis: 60%;
    }
    &Expanded {
      height: 700px;
    }

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  @media(max-width: $break-point-sm) {
    flex-basis: 100%;
  }
}

.viewMoreButton {
  text-align: center;

  @media(max-width: $break-point-sm) {
    padding-top: 0.75rem;
  }
}

.contentContainer {
  min-height: 350px;
  flex-basis: 100%;


  &Selected {
    flex-basis: 40%;
  }

  @media(max-width: $break-point-sm) {
    flex-basis: 100%;
    min-height: 275px;
  }
}

.transitionWrapper {
  @include mixins.translateTransition(translateY(20%), translateY(0));
  @include mixins.opacityTransition(0, 1);
}

:global(.sb-with-background-image) .filesBlockContainer {
  @media (min-width: $break-point-sm) {
    background-color: transparent;
  }
}

.filesSlider {
  display: inline-block;
  width: 100%;
}

.filesContainer {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: inline-block;

  &Single {
    width: 100%;
    text-align: center;
    padding-bottom: 2rem;

    article {
      width: 40%;
      display: inline-block;

      img {
        height: auto !important;
      }
    }
  }
}

.tableCell {
  vertical-align: middle;
  color: $clever-dgray;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 6px;
  line-height: 34px;

  &:nth-of-type(2) { // location column
    span:nth-of-type(1) {
      padding-right: 1rem;
    }
  }

  &:first-of-type {
    span:nth-of-type(2) {
      padding-left: .5rem; // external link icon for folders
    }
  }

  > span {
    color: $clever-dgray;
    vertical-align: middle;
  }

  a {
    span {
      color: $clever-dgray;
    }
  }

  img { // file type icon
    margin-right: 1rem;
    height: 24px;
    border-radius: 5px;
    vertical-align: middle;
    display: inline-block;
  }

  button {
    font-weight: 700;
  }

  &Hidden {
    opacity: 0.5;
  }
}

:global(.sb-with-background-image) .filesSliderContainer {
  @media (min-width: $break-point-sm) {
    background: linear-gradient(90deg, rgba(218,219,219,1) 0%, rgba(251,251,251,0.8) 65%, rgba(252,252,252,0) 100%);
  }
}

.filesSliderContainer {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  background: rgba(218,219,219,1);
  color: $clever-dgray;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 20px;
  @include mixins.opacityTransition(0, 1);
}

.tableRow {
  opacity: 0;
  transition: transform .25s ease, opacity .5s ease;

  &:hover, &[data-selected="true"] {
    background-color: rgba($google-color, .2);
  }
  &:hover {
    .editButton {
      display: inline-block;
    }
  }
}

.scrollContainer {
  max-height: 500px;
  overflow: scroll;
  position: relative;
  display: inline-block;
  width: 100%;
}

.fetchMore {
  padding: 1rem 0;
  text-align: center;
  background-color: $clever-lgray;
}

.filterButton {
  border-radius: 50%;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  box-shadow: 2px 2px 5px 2px lightgray;
  margin: 0 5px;

  img {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-top: 3.5px;
  }

  &Selected {
    opacity: .5;
  }
}
.sortByContainer {
  padding-top: 15px;
}
.filterButtonContainer {
  padding-top:6px;
  float: right;
}

.editButton {
  position: absolute;
  right: 25px;
  display: none;
}
.expandedPreviewButtonContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 70px;
  &NotEditor{
    right: 25px;
  }

}
.expandPreviewButton {
  position: relative;
  font-size: 1rem;
  background: $clever-lgray;
  border-radius: 10px;
  height: 20px;
  width: 70px;
  padding-left: 3px;
  padding-right: 3px;

  > span {
    transition: transform 0.3s ease-in-out;
  }
  &::before, &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    z-index: -1;
    border-radius: inherit;
    //filter: blur(1px);
    background: conic-gradient(from var(--angle), transparent var(--transparency), var(--secondaryNavBackground), var(--secondaryNavBackground));
    animation: 2s spin linear 1;
    animation-fill-mode: forwards;
  }
  @keyframes spin {
    0% {
      --angle: 0deg;
      --transparency: 0%;
      border: none;

    }
    25% {
      --transparency: 25%;
      border: none;

    }
    50% {
      --transparency: 50%;
      border: none;

    }
    75% {
      --transparency: 75%;
      border: none;

    }
    99% {
      --angle: 540deg;
      --transparency: 100%;
      border: none;
    }
    100% {
      --transparency: 100%;
      border: 2px solid var(--secondaryNavBackground);
    }
  }


  &:hover {
    > span {
      transform: scale(.9);
    }

  }

  &NotExpanded {
    &:hover {
      > span {
        transform: scale(1.2);
      }
    }
  }

  @media (max-width: $break-point-sm) {
    display: none;
  }
}
@property --angle{
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
@property --transparency {
  syntax: "<number>";
    initial-value: 0;
    inherits: false;
}

.expandPreviewButton.after.animation {
  animation: 2s spin linear infinite;
  animation-fill-mode: forwards;
}

.titleContainer {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(90% - 25px);
  &Selected {
    display: inline-block;
    vertical-align: top;
    width: calc(80% - 95px);
  }

}

.description {
  padding-left: calc(24px + 1rem);
  overflow: hidden;
  text-overflow: ellipsis;
}
